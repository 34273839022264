import { EmbedMutationRule, EmbedMutationType } from './types';
import URI from 'urijs';

/**
 * The Cookie wall detects embedded elements with tracking sources and replaces them with
 * untraceable URIs according to predefined rules.
 */
export class RdssCookieWall {
    mutationRules: EmbedMutationRule[] = [];

    /**
     * Constructor for RdssCookieWall.
     */
    constructor(mutationRules: EmbedMutationRule[]) {
        this.mutationRules = mutationRules;
        document.addEventListener('DOMContentLoaded', () => this.processEmbedElements());
    }

    /**
     *  Finds the embedded elements of a page and trigger the source mutations.
     */
    processEmbedElements(): void {
        document
            .querySelectorAll('iframe')
            ?.forEach(element => this.mutateEmbedElementSourceByRule(<HTMLIFrameElement>element));
    }

    /**
     * Replaces the source of the embedded element with an untraceable uri.
     */
    private mutateEmbedElementSourceByRule(element: HTMLIFrameElement): void {
        this.mutationRules.map((rule) => {
            if (!element.src.match(new RegExp(rule?.origin))) {
                return;
            }

            switch (rule?.mutate) {
            case EmbedMutationType.host: {
                return (element.src = element.src.replace(rule.origin, rule.value));
            }
            case EmbedMutationType.searchParams: {
                return (element.src = new URI(element.src).addSearch(rule.value).readable());
            }
            }
        });
    }
}
