import 'core-js/es/object/assign';
import 'nodelist-foreach-polyfill';
import { RdssTracker } from './rdss-tracker';
import { RdssCookieWall } from './cookie-wall';
import { ScreenDeviceMapper } from './screen-device-mapper';
import { embedMutationRules } from './types/globals';

export { RdssTracker };

window.rdssTracker = RdssTracker.getInstance();
window.rdssCookieWall = new RdssCookieWall(embedMutationRules);
window.rdssScreenDeviceMapper = new ScreenDeviceMapper();
