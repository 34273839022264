import { DeviceTypeMap, PageData } from './types';
import { RdssTracker } from './rdss-tracker';

interface StateManagerInterface {
    registerListener(a: object): void,
}

declare let StateManager: StateManagerInterface;

/**
 * ScreenDeviceMapper class.
 */
export class ScreenDeviceMapper {

    static readonly DEVICE_TYPE_MOBILE = 'mobile';
    static readonly DEVICE_TYPE_TABLET = 'tablet';
    static readonly DEVICE_TYPE_DESKTOP = 'desktop';

    static readonly deviceTypeMapping: DeviceTypeMap = {
        'uxs': ScreenDeviceMapper.DEVICE_TYPE_MOBILE,
        'xs': ScreenDeviceMapper.DEVICE_TYPE_MOBILE,
        's': ScreenDeviceMapper.DEVICE_TYPE_MOBILE,
        'm': ScreenDeviceMapper.DEVICE_TYPE_TABLET,
        'l': ScreenDeviceMapper.DEVICE_TYPE_TABLET,
        'xl': ScreenDeviceMapper.DEVICE_TYPE_DESKTOP,
    };

    /**
     * Constructor for ScreenDeviceMapper.
     */
    constructor() {
        document.addEventListener('DOMContentLoaded',() => {
            if (typeof StateManager !== 'undefined') {
                StateManager.registerListener({
                    state: '*',
                    enter: (transition: {exiting: string, entering: string}) => {
                        const mappedDeviceType = ScreenDeviceMapper.deviceTypeMapping[transition.entering];
                        const pageData: PageData = { deviceType: mappedDeviceType };

                        RdssTracker.getInstance().addPageData(pageData).apply();
                    },
                });
            }
        });
    }
}
