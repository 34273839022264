import { PageData, dataLayer } from './types';
import merge from 'deepmerge';

/**
 * RdssTracker class.
 */
export class RdssTracker {
    private static instance: RdssTracker;
    private pageDataState = <PageData>{};

    /**
     * Get singleton instance of RdssTracker.
     */
    static getInstance(): RdssTracker {
        this.instance = this.instance ?? new RdssTracker();
        return RdssTracker.instance;
    }

    /**
     * Merges the given page data into the PageStore
     * The page data object contains information about page, user, order and products.
     */
    addPageData(data: PageData): RdssTracker {
        Object.assign(this.pageDataState, merge(this.pageDataState, data));
        return this;
    }

    /**
     * Merges the page data store into the Data Layer.
     */
    apply(): void {
        Object.assign(dataLayer, this.pageDataState);
    }
}
