export interface EmbedMutationRule {
    mutate: EmbedMutationType;
    origin: string;
    value: string;
}

export enum EmbedMutationType {
    host,
    searchParams,
}
