import { PageData } from './page-data';
import { RdssTracker } from '../rdss-tracker';
import { RdssCookieWall } from '../cookie-wall';
import { ScreenDeviceMapper } from '../screen-device-mapper';
import { EmbedMutationRule, EmbedMutationType } from './embed-mutation-rule';

export const layerName = 'pageData';

export const embedMutationRules = <EmbedMutationRule[]>[
    {
        mutate: EmbedMutationType.host,
        origin: 'youtube.com',
        value: 'youtube-nocookie.com',
    },
    {
        mutate: EmbedMutationType.searchParams,
        origin: 'vimeo.com',
        value: 'dnt=1',
    },
];

declare global {
    interface Window {
        [layerName]: PageData;
        rdssTracker: RdssTracker;
        rdssCookieWall: RdssCookieWall;
        rdssScreenDeviceMapper: ScreenDeviceMapper;
    }
}
